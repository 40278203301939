import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import dynamic from 'next/dynamic'
const SeriesComparisonItem = dynamic(() => import('./SeriesComparisonItem'))
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import LazyLoad from 'react-lazyload'
import { optimizeImage } from '../../adapters/helpers/pagespeedHelper'
import { CarouselBlockConstants } from '../../adapters/helpers/Constants'
const Image = dynamic(() => import('../Image/Image'))
import DOMPurify from 'isomorphic-dompurify';

function ProductRecommenderABtest(props) {
    const { document: doc, onClickCallback, isMedium, isXXLarge, isSmall, isXSmall, isLarge, className } = props
    const isDesktopCarousel = doc?.fields?.isACarouselOnDesktop
    const leftArrow = doc?.fields?.arrowLeft
    const rightArrow = doc?.fields?.arrowRight
    const showProductLinks = doc?.fields?.showProductLinks

    const sliderRef = useRef(null)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [sliderSettings, setSliderSettings] = useState({
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        infinite: true,
        lazyLoad: false,
        autoplay: false,
        autoplaySpeed: 6000,
        dots: true,
        centerMode: true,
        centerPadding: '20px',
        nextArrow: <img tabIndex={0} src={optimizeImage(doc?.fields?.rightArrowIcon?.fields?.file?.url)} data-action-detail='carousel-next-arrow' alt={CarouselBlockConstants.rightArrow} />,
        prevArrow: <img tabIndex={0} src={optimizeImage(doc?.fields?.leftArrowIcon?.fields?.file?.url)} data-action-detail='carousel-previous-arrow' alt={CarouselBlockConstants.leftArrow} />,
    })

    const desktopCarouselSettings = {
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        speed: 1000,
        infinite: false,
        lazyLoad: false,
        autoplay: false,
        dots: false,
        beforeChange: (current, next) => setCurrentIndex(next),
    }
    const hideAriaHiddenTiles = () => {
        Array.from(document.querySelectorAll('.slick-slide')).forEach((slide) => {
            slide.setAttribute('aria-hidden', false)
        })
    }

    useEffect(() => {
        hideAriaHiddenTiles()
        const screenWidth = window.innerWidth
        const breakpoints = [
            { max: 360, padding: '28px' },
            { max: 376, padding: '35px' },
            { max: 390, padding: '40px' },
            { max: 414, padding: '53px' },
            { max: 430, padding: '60px' },
        ]
        const breakpoint = breakpoints.find((b) => screenWidth <= b.max) || { padding: '40px' }
        setSliderSettings({ ...sliderSettings, ...(doc?.fields?.carouselSettings || {}), fieldscenterPadding: breakpoint.padding })
    }, [])

    const sliderComponent = () => {
        return (
            <Slider {...sliderSettings}>
                {doc?.fields?.product?.map((productItem, i) => (
                    <ul className='ob-series-comparison_ul_item_mob' key={i}>
                        <li key={i} className='ob-series-comparison__item'>
                            <SeriesComparisonItem
                                showProductLinks={showProductLinks}
                                document={doc}
                                isMedium={isMedium}
                                isSmall={isSmall}
                                isXSmall={isXSmall}
                                product={productItem}
                                onClickCallback={onClickCallback ? onClickCallback : doc?.fields?.onClickCallback}
                            />
                        </li>
                    </ul>
                ))}
            </Slider>
        )
    }

    const desktopSliderComponent = () => {
        return (
            <div className='ob-desktop-product-slider'>
                {currentIndex !== 0 && (
                    <button className='ob-carousel-left-arrow' tabIndex={0} onClick={() => sliderRef.current.slickGoTo(currentIndex - 1)}>
                        <img src={optimizeImage(leftArrow?.fields?.file?.url)} alt='Left Arrow' />
                    </button>
                )}
                <Slider ref={sliderRef} {...desktopCarouselSettings}>
                    {doc?.fields?.product?.map((productItem, i) => (
                        <ul className='ob-series-ul' key={i}>
                            <li key={i} className='ob-series-comparison__item'>
                                <SeriesComparisonItem
                                    showProductLinks={showProductLinks}
                                    document={doc}
                                    isMedium={isMedium}
                                    isSmall={isSmall}
                                    isXSmall={isXSmall}
                                    product={productItem}
                                    onClickCallback={onClickCallback ? onClickCallback : doc?.fields?.onClickCallback}
                                />
                            </li>
                        </ul>
                    ))}
                </Slider>
                {doc?.fields?.product.length > 3 && currentIndex !== doc?.fields?.product.length - 3 && (
                    <button className='ob-carousel-right-arrow' onClick={() => sliderRef.current.slickGoTo(currentIndex + 1)}>
                        <img src={optimizeImage(rightArrow?.fields?.file?.url)} alt='Left Arrow' />
                    </button>
                )}
            </div>
        )
    }

    return (
        <LazyLoad offset={100}>
            <div className={`product-recommender-ab-test ${className || ''}`} id={doc?.fields?.anchorId}>
                <Image image={doc?.fields?.backgroundAsset}>
                    <div className='ob-series-comparison__sizer'>
                        {doc?.fields?.title && (
                            <h2 className='ob-series-comparison__title'>
                                <div>{doc?.fields?.title}</div>
                            </h2>
                        )}
                        {doc?.fields?.subtitle && <div className='subtitle-text'>{doc?.fields?.subtitle}</div>}
                        <div>
                            {(isMedium || isXSmall || isSmall) && <>{sliderComponent()}</>}
                            {!isMedium && !isXSmall && !isSmall && isDesktopCarousel && <>{desktopSliderComponent()}</>}
                            {!isMedium && !isXSmall && !isSmall && !isDesktopCarousel && (
                                <ul className='ob-series-comparison__list-product'>
                                    {doc?.fields?.product?.map((productItem, i) => (
                                        <li key={i} className='ob-series-comparison__item'>
                                            <SeriesComparisonItem showProductLinks={showProductLinks} document={doc} product={productItem} onClickCallback={onClickCallback ? onClickCallback : doc?.fields?.onClickCallback} />
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        {doc?.fields?.seeAllProductsLinks && <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(doc?.fields?.seeAllProductsLinks) }} className='see-all-products-conatiner' />}
                    </div>
                </Image>
            </div>
        </LazyLoad>
    )
}
export default mediaQueryHOC(ProductRecommenderABtest)
ProductRecommenderABtest.propTypes = {
    document: PropTypes.any,
    products: PropTypes.any,
    selectedSeries: PropTypes.object,
    onClickCallback: PropTypes.func,
    isMedium: PropTypes.bool,
    isLarge: PropTypes.bool,
}
