import { getConfigurationBooleanValue, getConfigurationValue } from '../../adapters/helpers/ContentBlockConfiguration';
import React, {Component} from 'react';
import Block from '../../adapters/helpers/Block';
import dynamic from 'next/dynamic';
const Image = dynamic(()=>import('../Image/Image'))
import LazyLoad from 'react-lazyload';
import {Waypoint} from 'react-waypoint';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import Modal from 'react-modal';
const Icon = dynamic(()=>import('../Icon/Icon'))
const Heading = dynamic(()=>import('../Heading/Heading'))
const BodyText = dynamic(()=>import('../BodyText/BodyText'))
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import PropTypes from 'prop-types';
import {ContentConstants, ContentVideoConstants, SpotlightContentVideoConstants} from '../../adapters/helpers/Constants';
import {Label} from '../Label/Label';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';
const Disclaimer = dynamic(()=>import('../Disclaimer/Disclaimer'))
import {getRGBFromHexColor, stripHtml} from '../../adapters/helpers/Utils';
const YoutubeVideo = dynamic(() => import('../YoutubeVideo/YoutubeVideo'))
import DOMPurify from 'isomorphic-dompurify';

/**
 * TODOs
 *  - Proper style for video modal
 *  - Proper interaction for video transcript (currently in visuallyhidden container)
 *  - add back-end copy for playVideoCopy and closeVideoCopy (for accessibility purposes)
 */

/**
 *
 * Supported Classes:
 *  By default the background is white and the text color is primary-grey.
 *  - white-text : change the text color to white
 *  - align-center : force a text-align: center without impacting the text container alignment
 *
 *  - background-primary-grey : change the background to primary grey
 *  - background-secondary-grey : change the background to secondary grey
 *  - background-secondary-off-white : change the background to secondary-off-white
 *  - background-isolate-grey : change the background to isolate grey
 *  - background-primary-blue : change the background to primary-blue
 *  - background-secondary-blue : change the background to secondary-blue
 *
 */

const customStyles = {
    overlay: {
        position: 'fixed',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content : {
        border              : 'none',
        top                 : '50%',
        left                : '50%',
        right               : 'auto',
        bottom              : 'auto',
        padding             : '0',
        marginRight         : '-50%',
        transform           : 'translate(-50%, -50%)',
        maxWidth            : '100rem',
        width               : '90%'
    }
};

const customTranscriptStyles = {
    overlay: {
        position: 'fixed',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content : {
        border              : 'none',
        top                 : '50%',
        left                : '50%',
        right               : 'auto',
        bottom              : 'auto',
        padding             : '45px 20px 20px',
        fontSize            : '1.4rem',
        lineHeight          : '2.1rem',
        marginRight         : '-50%',
        transform           : 'translate(-50%, -50%)',
        maxWidth            : '123.6rem',
        width               : '90%'
    }
};


class ContentVideo extends Component {
    constructor(props) {
        super(props);
        this.block = new Block(props);
        this.videoBlockRef = React.createRef();
        this.videoPlayerRef = React.createRef();
        this.modalRef = React.createRef();
        this.modalOverlayRef = null;

        this.state = {
            modalIsOpen: false,
            isPlaying: false
        };

        this.openModal = this.openModal.bind(this);
        this.handlePlayClick = this.handlePlayClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleVideoEvent = this.handleVideoEvent.bind(this);
        this.trapFocusInModal = this.trapFocusInModal.bind(this);
        this.availableConfigurations = this.valueOf(ContentConstants.devicesConfigurations)?.map(configuration => configuration.fields.deviceType);
    }

    handlePlayClick() {
        const videoRef = this.videoPlayerRef.current?.getVideoRef();
        const videoPlayerControlsRef = this.videoPlayerRef?.current?.getVideoPlayerRef();

        if (videoPlayerControlsRef && videoRef && videoRef instanceof HTMLVideoElement) {
            if (this.state.isPlaying) {
                videoPlayerControlsRef.pause();
            } else {
                videoPlayerControlsRef.play();
            }
        }
    }

    handleVideoEvent(value) {
        this.setState({isPlaying: value});
    }

    handleResetClick() {
        const videoPlayerControlsRef = this.videoPlayerRef?.current?.getVideoPlayerRef();

        if (videoPlayerControlsRef) {
            videoPlayerControlsRef.currentTime(0);
            videoPlayerControlsRef.play();
        }
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        if (this.modalOverlayRef && this.modalOverlayRef.getAttribute('data-action-detail') == null) {
            this.modalOverlayRef.setAttribute('data-action-detail', AnalyticsConstants.contentVideoOverlayText);
        }
    }

    closeModal() {
        this.setState({modalIsOpen: false});
    }

    trapFocusInModal(event) {
        // The modal traps focus by default, however
        // the Cloudinary video player has many hidden 'focusable' elements.
        // The modal was trying to focus on elements that were hidden in the DOM,
        // so it focused on the rest of the page instead.
        if (!modalIsOpen) {
            return;
        }

        const element = event.currentTarget;
        const focusableElements = element.querySelectorAll('.vjs-control-bar button:not([disabled]), .vjs-control-bar a[href]:not([disabled]), .ob-modal-close');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];
        const KEYCODE_TAB = 9;

        const isTabPressed = (event.key === ContentVideoConstants.Tab || event.keyCode === KEYCODE_TAB);

        if (!isTabPressed) {
            return;
        }

        if ( event.shiftKey ) /* shift + tab */ {
            if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus();
                event.preventDefault();
            }
        } else /* tab */ {
            if (document.activeElement === lastFocusableElement) {
                firstFocusableElement.focus();
                event.preventDefault();
            }
        }
    }

    valueOf(name) {
        return this.block.getFieldValue(name);
    }

    generateContentblockState() {
        return {
            availableConfigurations: this.valueOf(ContentConstants.devicesConfigurations)?.map(configuration => configuration.fields.deviceType),
            blockMinHeight: this.configurationValue(ContentConstants.blockMinHeight, this.isMedium, this.isXSmall, this.isXLarge, ''),
            blockMarginTop: this.configurationValue(ContentConstants.blockMarginTop, this.isMedium, this.isXSmall, this.isXLarge, ''),
            blockMarginBottom: this.configurationValue(ContentConstants.blockMarginBottom, this.isMedium, this.isXSmall, this.isXLarge, ''),
            blockMarginRight: this.configurationValue(ContentConstants.blockMarginRight, this.isMedium, this.isXSmall, this.isXLarge, ''),
            blockMarginLeft: this.configurationValue(ContentConstants.blockMarginLeft, this.isMedium, this.isXSmall, this.isXLarge, ''),
            blockPadding: this.configurationValue(ContentConstants.blockPadding, this.isMedium, this.isXSmall, this.isXLarge, ''),
            textAlign: this.configurationValue(ContentConstants.textAlign, this.isMedium, this.isXSmall, this.isXLarge, ''),
            textContainerWidth: this.configurationValue(ContentConstants.textContainerWidth, this.isMedium, this.isXSmall, this.isXLarge, ''),
            titleWidth: this.configurationValue(ContentConstants.titleWidth, this.isMedium, this.isXSmall, this.isXLarge, ''),
            descriptionWidth: this.configurationValue(ContentConstants.descriptionWidth, this.isMedium, this.isXSmall, this.isXLarge, ''),
            textContainerHorizontalAlignment: this.configurationValue(ContentConstants.textContainerHorizontalAlignment, this.isMedium, this.isXSmall, this.isXLarge, ''),
            textContainerVerticalAlignment: this.configurationValue(ContentConstants.textContainerVerticalAlignment, this.isMedium, this.isXSmall, this.isXLarge, ''),
            textContainerOffset: this.configurationValue(ContentConstants.textContainerOffset, this.isMedium, this.isXSmall, this.isXLarge, ''),
            textContainerMarginRight: this.configurationValue(ContentConstants.textContainerMarginRight, this.isMedium, this.isXSmall, this.isXLarge, ''),
            textContainerMarginLeft: this.configurationValue(ContentConstants.textContainerMarginLeft, this.isMedium, this.isXSmall, this.isXLarge, ''),
            textContainerMarginBottom: this.configurationValue(ContentConstants.textContainerMarginBottom, this.isMedium, this.isXSmall, this.isXLarge, ''),
            mainAsset: this.configurationValue(ContentConstants.mainAsset, this.isMedium, this.isXSmall, this.isXLarge,),
            mainAssetBelowOrAboveText: this.configurationValue(ContentConstants.mainAssetBelowOrAboveText, this.isMedium, this.isXSmall, this.isXLarge, ''),
            mainAssetMaxWidth: this.configurationValue(ContentConstants.mainAssetMaxWidth, this.isMedium, this.isXSmall, this.isXLarge, ''),
            mainAssetMaxHeight: this.configurationValue(ContentConstants.mainAssetMaxHeight, this.isMedium, this.isXSmall, this.isXLarge, ''),
            mainAssetHeight: this.configurationValue(ContentConstants.mainAssetHeight, this.isMedium, this.isXSmall, this.isXLarge, ''),
            mainAssetOffsetTop: this.configurationValue(ContentConstants.mainAssetMarginTop, this.isMedium, this.isXSmall, this.isXLarge, ''),
            mainAssetOffsetRight: this.configurationValue(ContentConstants.mainAssetMarginRight, this.isMedium, this.isXSmall, this.isXLarge,''),
            mainAssetOffsetBottom: this.configurationValue(ContentConstants.mainAssetOffsetBottom, this.isMedium, this.isXSmall, this.isXLarge, ''),
            mainAssetOffsetLeft: this.configurationValue(ContentConstants.mainAssetOffsetLeft, this.isMedium, this.isXSmall, this.isXLarge, ''),
            mainAssetScale: this.configurationValue(ContentConstants.mainAssetScale, this.isMedium, this.isXSmall, this.isXLarge, ''),
            backgroundAsset: this.configurationValue(ContentConstants.backgroundAsset, this.isMedium, this.isXSmall, this.isXLarge),
            mainAssetHorizontalAlignment: this.configurationValue(ContentConstants.mainAssetHorizontalAlignment, this.isMedium, this.isXSmall, this.isXLarge),
            mainAssetVerticalAlignment: this.configurationValue(ContentConstants.mainAssetVerticalAlignment, this.isMedium, this.isXSmall, this.isXLarge),
            backgroundPosition: this.configurationValue(ContentConstants.backgroundPosition, this.isMedium, this.isXSmall, this.isXLarge, ''),
            sectionOffsetTop: this.configurationValue(ContentConstants.sectionOffsetTop, this.isMedium, this.isXSmall, this.isXLarge, ''),
            mainAssetParallax: this.configurationValue(ContentConstants.mainAssetParallax, this.isMedium, this.isXSmall, this.isXLarge, ''),
            mainAssetParallaxSetting: this.configurationValue(ContentConstants.mainAssetParallaxSetting, this.isMedium, this.isXSmall, this.isXLarge, ''),
            textParallax: this.configurationValue(ContentConstants.textParallax, this.isMedium, this.isXSmall, this.isXLarge, ''),
            textParallaxSetting: this.configurationValue(ContentConstants.textParallaxSetting, this.isMedium, this.isXSmall, this.isXLarge, ''),
            textParallaxConfiguration: this.configurationValue(ContentConstants.textParallaxConfiguration, this.isMedium, this.isXSmall, this.isXLarge, ''),
            assetParallaxConfiguration: this.configurationValue(ContentConstants.assetParallaxConfiguration, this.isMedium, this.isXSmall, this.isXLarge, ''),
            textShowAnimation: this.configurationValue(ContentConstants.textShowAnimation, this.isMedium, this.isXSmall, this.isXLarge, ''),
            textFadeIn: this.configurationBooleanValue(ContentConstants.textFadeIn, this.isMedium, this.isXSmall, this.isXLarge),
            textColor: this.configurationBooleanValue(ContentConstants.textColor, this.isMedium, this.isXSmall, this.isXLarge),
            respectImageAspectRatio: this.configurationBooleanValue(ContentConstants.respectImageAspectRatio, this.isMedium, this.isXSmall, this.isXLarge),
            textContainerBackgroundColor: this.configurationValue(ContentConstants.textContainerBackgroundColor, this.isMedium, this.isXSmall, this.isXLarge),
            textContainerBackgroundOpacity: this.configurationValue(ContentConstants.textContainerBackgroundOpacity, this.isMedium, this.isXSmall, this.isXLarge),
        };
    }

    configurationValue(fieldName, isMedium, isXSmall, isXLarge, defaultValue = false) {
        return this.availableConfigurations ? getConfigurationValue(this.availableConfigurations,
            this.valueOf(ContentConstants.devicesConfigurations),
            fieldName,
            isMedium,
            isXSmall,
            isXLarge,
            defaultValue) : null;
    }

    configurationBooleanValue(fieldName, isMedium, isXSmall, isXLarge, defaultValue = false) {
        return this.availableConfigurations ? getConfigurationBooleanValue(this.availableConfigurations,
            this.valueOf(ContentConstants.devicesConfigurations),
            fieldName,
            isMedium,
            isXSmall,
            isXLarge,
            defaultValue) : defaultValue;
    }

    componentDidMount() {
        Modal.setAppElement('.js-modal-container');
        if (this.modalRef && this.modalRef.current && this.modalRef.current.node) {
            this.modalRef.current.node.addEventListener(ContentVideoConstants.keydown, this.trapFocusInModal);
        }
    }

    componentWillUnmount() {
        if (this.modalRef && this.modalRef.current && this.modalRef.current.node) {
            this.modalRef.current.node.removeEventListener(ContentVideoConstants.keydown, this.trapFocusInModal);
        }
    }

    _handleWaypointEnter = () => {
        // Animation on the text container on the 'onEnter' event
        // Trigger by waypoint
        const node = this.videoBlockRef.current;
        node.classList.add('show');
    };

    renderVideo = (video) => {

        if (video) {
            if (video?.videoId) {
                return <YoutubeVideo document={video} />
            } else {
                return <VideoPlayer video={video} />
            }
        }
    };

    render() {
        // Can have the class white-text for color white,
        // or align-center for centering the text (Left align by default)
        // Can have the class button-right or button-top for play button position

        this.isXSmall = this.props.isXSmall;
        this.isMedium = this.props.isMedium;
        this.isXLarge = this.props.isXLarge;

        const mobileLayout = this.valueOf(ContentVideoConstants.mobileLayout);
        const desktopLayout = this.valueOf(ContentVideoConstants.desktopLayout);
        let className = 'ob-contentBlock ob-videoBlock js-modal-container ';
        let isSideImage = false;

        if (this.valueOf(ContentVideoConstants.classNames)) {
            className += this.valueOf(ContentVideoConstants.classNames);
        }

        if (this.props.isMedium) {
            className += ' layout-' + mobileLayout;
            isSideImage = false;
        } else {
            className += ' layout-' + desktopLayout;
            isSideImage = desktopLayout == 'image-left' || desktopLayout == 'image-right';
        }

        const blockState = this.generateContentblockState();

        const isWhiteText = className.includes(ContentVideoConstants.whiteText);
        const mobileBkgImage = mobileLayout && mobileLayout.includes(ContentVideoConstants.backgroundImage);
        const desktopBkgImage = desktopLayout && desktopLayout.includes(ContentVideoConstants.backgroundImage);
        const imageIsBackground = (this.props.isMedium && mobileBkgImage) || (!this.props.isMedium && desktopBkgImage);

        const title = this.valueOf(ContentVideoConstants.title);
        const titleLevel = this.valueOf(ContentVideoConstants.titleLevel);
        const anchorId = this.block.getAnchorId();
        const video = this.block.getFields().video.fields;
        const keyframeImage = this.props.isMedium && video?.mobileKeyframe ? video?.mobileKeyframe : video.keyframeImage;
        const videoAutoplayOnScroll = video?.autoplay == 'on-scroll';

        const textContainerMarginBottom = this.valueOf(ContentVideoConstants.textContainerMarginBottom);
        const textContainerMarginTop = this.valueOf(ContentVideoConstants.textContainerMarginTop);
        const playIconColor = this.valueOf(ContentVideoConstants.playIconColor);
        const backgroundColor = this.valueOf(ContentVideoConstants.backgroundColor);
        const disclaimer = this.valueOf(ContentVideoConstants.disclaimer);
        const eyebrow = this.valueOf(ContentVideoConstants.surtitle);
        const blueEyebrow = this.valueOf(ContentVideoConstants.blueEyebrow);
        const playVideoCopy = Label({label: this.block.getFieldValue(ContentVideoConstants.playLabel)});
        const closeVideoCopy = Label({label: this.block.getFieldValue(ContentVideoConstants.closeLabel)});
        const pauseVideoCopy = Label({label: this.block.getFieldValue(ContentVideoConstants.pauseLabel)});
        const resetVideoCopy = Label({label: this.block.getFieldValue(ContentVideoConstants.resetLabel)});
        const openTranscriptCopy = Label({label: this.block.getFieldValue(ContentVideoConstants.openTranscriptLabel)});
        const closeTranscriptCopy = Label({label: this.block.getFieldValue(ContentVideoConstants.closeTranscriptLabel)});
        const isPlayIconWhite = playIconColor === ContentVideoConstants.whiteHexThreeChars || playIconColor === ContentVideoConstants.whiteHexFull || playIconColor === ContentVideoConstants.white;

        let textContainerBackgroundColor;
        let textContainerBackgroundColorRGB = blockState?.textContainerBackgroundColor ? blockState?.textContainerBackgroundColor : null;
        let textContainerBackgroundOpacity = 1;
        if (blockState?.textContainerBackgroundOpacity) {
            textContainerBackgroundOpacity = blockState?.textContainerBackgroundOpacity;
        }

        if (textContainerBackgroundColorRGB) {
            let colorRgb = getRGBFromHexColor(textContainerBackgroundColorRGB);
            textContainerBackgroundColor = `rgba(${colorRgb.red}, ${colorRgb.green}, ${colorRgb.blue}, ${textContainerBackgroundOpacity})`;
        }

        const getPlayIconSize = () => {
            let playIconSize = '6';

            if (!this.props.isMedium) {
                playIconSize = '10';
            }
            return playIconSize;
        };

        return (
            <section className={className} id={anchorId}
                 style={{
                     backgroundColor: backgroundColor,
                     marginTop: blockState.blockMarginTop ? blockState.blockMarginTop : null,
                     marginBottom: blockState.blockMarginBottom ? blockState.blockMarginBottom : null,
                     marginRight: blockState.blockMarginRight ? blockState.blockMarginRight : null,
                     marginLeft: blockState.blockMarginLeft ? blockState.blockMarginLeft : null
                 }}>
                {imageIsBackground ? (
                    <LazyLoad offset={200} once={true}>
                        <Image image={keyframeImage}>
                            <Waypoint onEnter={this._handleWaypointEnter} />
                            <div className={`ob-videoContent-wrapper
                                ${blockState.textContainerHorizontalAlignment && `horizontal-${blockState.textContainerHorizontalAlignment}`}
                                ${blockState.textContainerVerticalAlignment && `vertical-${blockState.textContainerVerticalAlignment}`}
                            `}
                                 style={{padding: blockState.blockPadding ? blockState.blockPadding : null}}>
                                {blockState.mainAsset &&
                                    <div
                                        className={`ob-videoContent-mainAsset-container
                                            ${blockState.mainAssetHorizontalAlignment && `horizontal-${blockState.mainAssetHorizontalAlignment}`}
                                            ${blockState.mainAssetVerticalAlignment && `vertical-${blockState.mainAssetVerticalAlignment}`}`}>
                                        <Image
                                            scale={blockState.mainAssetScale}
                                            image={blockState.mainAsset}
                                            offsetBottom={blockState.mainAssetOffsetBottom ? blockState.mainAssetOffsetBottom : null}
                                            offsetTop={blockState.mainAssetOffsetTop ? blockState.mainAssetOffsetTop : null}
                                            offsetLeft={blockState.mainAssetOffsetLeft ? blockState.mainAssetOffsetLeft : null}
                                            offsetRight={blockState.mainAssetOffsetRight ? blockState.mainAssetOffsetRight : null}
                                        />
                                    </div>
                                }
                                <div className='ob-videoContent-textContainer'
                                     ref={this.videoBlockRef}
                                     style={{
                                         marginTop: blockState.textContainerOffset ? blockState.textContainerOffset : textContainerMarginTop,
                                         marginBottom: blockState.textContainerMarginBottom ? blockState.textContainerMarginBottom : textContainerMarginBottom,
                                         marginLeft: blockState.textContainerMarginLeft ? blockState.textContainerMarginLeft : null,
                                         marginRight: blockState.textContainerMarginRight ? blockState.textContainerMarginRight : null,
                                         backgroundColor: textContainerBackgroundColor ? textContainerBackgroundColor  : null,
                                         textAlign: blockState.textAlign ? blockState.textAlign : null,
                                         width: blockState.textContainerWidth ? blockState.textContainerWidth : null
                                     }}>
                                    <div className='ob-videoContent-text'>
                                        {eyebrow &&
                                            <p
                                                className={'ob-eyebrow'}
                                                data-white-text={isWhiteText}
                                                data-blue-text={blueEyebrow}
                                                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.valueOf(ContentVideoConstants.surtitle))}}>
                                            </p>
                                        }

                                        <Heading
                                            whiteText={isWhiteText}
                                            tag={`h${titleLevel}`}
                                            className='ob-display-2-xl'
                                            styles={{ width: blockState.titleWidth ? blockState.titleWidth : null }}
                                        >{title}</Heading>

                                        <BodyText
                                            whiteText={isWhiteText}
                                            styles={{ width: blockState.descriptionWidth ? blockState.descriptionWidth : null }}
                                        >{this.valueOf(ContentVideoConstants.description)}</BodyText>
                                    </div>

                                    <div className='ob-videoContent-button-container'>
                                        <button onClick={this.openModal}
                                                className={`event_button_click ob-videoContent-button ${isPlayIconWhite ? 'ob-videoContent-button--whitePlayIcon' : ''}`}
                                                data-action-detail={AnalyticsConstants.openVideoText}
                                                aria-label={playVideoCopy}>
                                            <Icon name={ContentVideoConstants.play} color={playIconColor} viewBox={31.4} size={getPlayIconSize()} />
                                        </button>
                                    </div>
                                </div>
                                {disclaimer &&
                                    <div className={'ob-videoContent-disclaimer'}
                                        style={{
                                            width: blockState.textContainerWidth ? blockState.textContainerWidth : null,
                                            textAlign: blockState.textAlign ? blockState.textAlign : null
                                        }}
                                    >
                                        <Disclaimer whiteText={isWhiteText} document={disclaimer} />
                                    </div>
                                }
                            </div>
                        </Image>
                    </LazyLoad>
                ) : (
                    <LazyLoad offset={200} once={true}>
                        <Waypoint onEnter={this._handleWaypointEnter} />
                        <div className={`ob-videoContent-wrapper
                                ${blockState.textContainerHorizontalAlignment && `horizontal-${blockState.textContainerHorizontalAlignment}`}
                                ${blockState.textContainerVerticalAlignment && `vertical-${blockState.textContainerVerticalAlignment}`}
                            `}
                             style={{padding: blockState.blockPadding ? blockState.blockPadding : null}}>
                            <div className='ob-videoContent-textContainer'
                                ref={this.videoBlockRef}
                                style={{
                                    marginTop: blockState.textContainerOffset ? blockState.textContainerOffset : textContainerMarginTop,
                                    marginBottom: blockState.textContainerMarginBottom ? blockState.textContainerMarginBottom : textContainerMarginBottom,
                                    marginLeft: blockState.textContainerMarginLeft ? blockState.textContainerMarginLeft : null,
                                    marginRight: blockState.textContainerMarginRight ? blockState.textContainerMarginRight : null,
                                    backgroundColor: textContainerBackgroundColor ? textContainerBackgroundColor  : null,
                                    textAlign: blockState.textAlign ? blockState.textAlign : null,
                                    width: blockState.textContainerWidth ? blockState.textContainerWidth : null
                                }}>
                                <div className='ob-videoContent-text'>
                                    {eyebrow &&
                                        <p
                                            className={'ob-eyebrow'}
                                            data-white-text={isWhiteText}
                                            data-blue-text={blueEyebrow}
                                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.valueOf(ContentVideoConstants.surtitle))}}>
                                        </p>
                                    }

                                    <Heading
                                        whiteText={isWhiteText}
                                        tag={`h${titleLevel}`}
                                        className='ob-display-2-xl'
                                        styles={{ width: blockState.titleWidth ? blockState.titleWidth : null }}
                                    >{title}</Heading>

                                    <BodyText
                                        whiteText={isWhiteText}
                                        styles={{ width: blockState.descriptionWidth ? blockState.descriptionWidth : null }}
                                    >{this.valueOf(ContentVideoConstants.description)}</BodyText>
                                </div>
                            </div>

                            {isSideImage &&
                                <div className='ob-videoContent-image' style={{
                                    width: blockState.mainAssetMaxWidth ? blockState.mainAssetMaxWidth : null,
                                    height: blockState.mainAssetHeight ? blockState.mainAssetHeight  : null,
                                    maxHeight: blockState.mainAssetMaxHeight ? blockState.mainAssetMaxHeight : null,
                                    marginRight: blockState.mainAssetOffsetRight ? blockState.mainAssetOffsetRight : null,
                                    marginLeft: blockState.mainAssetOffsetLeft ? blockState.mainAssetOffsetLeft : null,
                                    marginTop: blockState.mainAssetOffsetTop ? blockState.mainAssetOffsetTop : null,
                                    marginBottom: blockState.mainAssetOffsetBottom ? blockState.mainAssetOffsetBottom : null,
                                }}>

                                    {videoAutoplayOnScroll ? (
                                        <div className='ob-autoplay-video'>
                                            <VideoPlayer ref={this.videoPlayerRef} video={video} onVideoPlaying={this.handleVideoEvent}  />

                                            <div className='ob-autoplay-video-controls'>
                                                <button onClick={this.handlePlayClick}
                                                        aria-label={this.state.isPlaying ? pauseVideoCopy : playVideoCopy}
                                                        style={{
                                                            color: [playIconColor],
                                                            outlineColor: [playIconColor],
                                                            height: '2.3rem',
                                                            position: 'absolute',
                                                            bottom: '0.5rem',
                                                            left: '2.3rem',
                                                            zIndex: '10'
                                                        }}
                                                        className="event_video_play ob-video-player-button"
                                                        data-action-detail={this.state.isPlaying ? SpotlightContentVideoConstants.paused : SpotlightContentVideoConstants.played}>
                                                    {this.state.isPlaying ?
                                                        (
                                                            <Icon name={SpotlightContentVideoConstants.spotlightPause}
                                                                  color={playIconColor}
                                                                  size={'1.4'}/>
                                                        ) : (
                                                            <Icon name={SpotlightContentVideoConstants.spotlightPlay}
                                                                  color={playIconColor}
                                                                  size={'1.6'}/>
                                                        )
                                                    }
                                                </button>

                                                <button onClick={this.handleResetClick}
                                                        aria-label={resetVideoCopy}
                                                        className="ob-video-player-button ob-video-reset-button"
                                                        style={{
                                                            color: [playIconColor],
                                                            outlineColor: [playIconColor],
                                                            position: 'absolute',
                                                            height: '2.3rem',
                                                            bottom: '0.5rem',
                                                            left: '4.8rem',
                                                            zIndex: '10'
                                                        }}>
                                                    <Icon name={SpotlightContentVideoConstants.spotlightReplay}
                                                          color={playIconColor}
                                                          size={'1.7'}/>
                                                </button>

                                                {video && video.transcript &&
                                                    <button onClick={this.openModal}
                                                            aria-label={'open Transcript'}
                                                            className="ob-video-player-button ob-video-show-transcript"
                                                            style={{
                                                                color: [playIconColor],
                                                                outlineColor: [playIconColor],
                                                                position: 'absolute',
                                                                height: '2.3rem',
                                                                bottom: '0.4rem',
                                                                left: '7.8rem',
                                                                zIndex: '10'
                                                            }}>
                                                        <Icon name={SpotlightContentVideoConstants.spotlightTranscript}
                                                              color={playIconColor}
                                                              size={'1.3'}/>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    ):(
                                        <Image image={keyframeImage} />
                                    )}

                                    {!videoAutoplayOnScroll &&
                                        <button onClick={this.openModal}
                                                className={`event_button_click ob-videoContent-button ${isPlayIconWhite ? 'ob-videoContent-button--whitePlayIcon' : ''}`}
                                                data-action-detail={AnalyticsConstants.openVideoText}
                                                aria-label={`${playVideoCopy} ${title}`}>
                                            <Icon name={ContentVideoConstants.play} viewBox={31.4} color={playIconColor}
                                                  size={getPlayIconSize()}/>
                                        </button>
                                    }
                                </div>
                            }

                            {disclaimer &&
                                <div className={'ob-videoContent-disclaimer'}
                                    style={{
                                        width: blockState.textContainerWidth ? blockState.textContainerWidth : null,
                                        textAlign: blockState.textAlign ? blockState.textAlign : null
                                    }}
                                >
                                    <Disclaimer whiteText={isWhiteText} document={disclaimer} />
                                </div>
                            }
                        </div>

                        {!isSideImage &&
                            <div className='ob-videoContent-image'
                                 style={{
                                     width: blockState.mainAssetMaxWidth ? blockState.mainAssetMaxWidth : null,
                                     height: blockState.mainAssetHeight ? blockState.mainAssetHeight  : null,
                                     maxHeight: blockState.mainAssetMaxHeight ? blockState.mainAssetMaxHeight : null,
                                     marginRight: blockState.mainAssetOffsetRight ? blockState.mainAssetOffsetRight : null,
                                     marginLeft: blockState.mainAssetOffsetLeft ? blockState.mainAssetOffsetLeft : null,
                                     marginTop: blockState.mainAssetOffsetTop ? blockState.mainAssetOffsetTop : null,
                                     marginBottom: blockState.mainAssetOffsetBottom ? blockState.mainAssetOffsetBottom : null}}>
                                {videoAutoplayOnScroll ? (
                                    <div className='ob-autoplay-video'>
                                        <VideoPlayer ref={this.videoPlayerRef} video={video} onVideoPlaying={this.handleVideoEvent} />

                                        <div className='ob-autoplay-video-controls'>
                                            <button onClick={this.handlePlayClick}
                                                    aria-label={this.state.isPlaying ? pauseVideoCopy : playVideoCopy}
                                                    style={{
                                                        color: [playIconColor],
                                                        outlineColor: [playIconColor],
                                                        height: '2.3rem',
                                                        position: 'absolute',
                                                        bottom: '0.5rem',
                                                        left: '2.3rem',
                                                        zIndex: '10'
                                                    }}
                                                    className="event_video_play ob-video-player-button"
                                                    data-action-detail={this.state.isPlaying ? SpotlightContentVideoConstants.paused : SpotlightContentVideoConstants.played}>
                                                {this.state.isPlaying ?
                                                    (
                                                        <Icon name={SpotlightContentVideoConstants.spotlightPause}
                                                              color={playIconColor}
                                                              size={'1.4'}/>
                                                    ) : (
                                                        <Icon name={SpotlightContentVideoConstants.spotlightPlay}
                                                              color={playIconColor}
                                                              size={'1.6'}/>
                                                    )
                                                }
                                            </button>

                                            <button onClick={this.handleResetClick}
                                                    aria-label={resetVideoCopy}
                                                    className="ob-video-player-button ob-video-reset-button"
                                                    style={{
                                                        color: [playIconColor],
                                                        outlineColor: [playIconColor],
                                                        position: 'absolute',
                                                        height: '2.3rem',
                                                        bottom: '0.5rem',
                                                        left: '4.8rem',
                                                        zIndex: '10'
                                                    }}>
                                                <Icon name={SpotlightContentVideoConstants.spotlightReplay}
                                                      color={playIconColor}
                                                      size={'1.7'}/>
                                            </button>

                                            {video && video.transcript &&
                                            <button onClick={this.openModal}
                                                    aria-label={openTranscriptCopy}
                                                    className="ob-video-player-button ob-video-show-transcript"
                                                    style={{
                                                        color: [playIconColor],
                                                        outlineColor: [playIconColor],
                                                        position: 'absolute',
                                                        height: '2.3rem',
                                                        bottom: '0.4rem',
                                                        left: '7.8rem',
                                                        zIndex: '10'
                                                    }}>
                                                <Icon name={SpotlightContentVideoConstants.spotlightTranscript}
                                                      color={playIconColor}
                                                      size={'1.3'}/>
                                            </button>
                                            }
                                        </div>
                                    </div>
                                ):(
                                    <Image image={keyframeImage} />
                                )}

                                {!videoAutoplayOnScroll &&
                                    <button onClick={this.openModal}
                                            className={`event_button_click ob-videoContent-button ${isPlayIconWhite ? 'ob-videoContent-button--whitePlayIcon' : ''}`}
                                            data-action-detail={AnalyticsConstants.openVideoText}
                                            aria-label={playVideoCopy}>
                                        <Icon name={ContentVideoConstants.play} viewBox={31.4} color={playIconColor}
                                              size={getPlayIconSize()}/>
                                    </button>
                                }
                            </div>
                        }
                    </LazyLoad>
                )}

                {!videoAutoplayOnScroll &&
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal.bind(this)}
                        onRequestClose={this.closeModal.bind(this)}
                        closeTimeoutMS={250}
                        style={customStyles}
                        overlayClassName={'event_button_click'}
                        contentLabel={video.transcript ? video.transcript : stripHtml(this.valueOf(ContentVideoConstants.title))}
                        overlayRef={node => (this.modalOverlayRef = node)}
                        ref={this.modalRef}
                    >
                        {this.renderVideo(video)}

                        <button className='event_close_window ob-modal-close'
                                onClick={this.closeModal}
                                aria-label={closeTranscriptCopy}>
                            <Icon name={ContentVideoConstants.close} size='2.4' color='#FFFFFF'/>
                        </button>
                    </Modal>
                }

                {videoAutoplayOnScroll && video && video.transcript &&
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal.bind(this)}
                        onRequestClose={this.closeModal.bind(this)}
                        closeTimeoutMS={250}
                        style={customTranscriptStyles}
                        overlayClassName={'event_button_click'}
                        contentLabel={video.transcript ? video.transcript : stripHtml(this.valueOf(ContentVideoConstants.title))}
                        overlayRef={node => (this.modalOverlayRef = node)}
                        ref={this.modalRef}
                    >
                        <button className='event_close_window ob-modal-close no-shadow'
                                onClick={this.closeModal}
                                aria-label={closeVideoCopy}>
                            <Icon name={ContentVideoConstants.close} size='2' color='#0057B8' />
                        </button>

                        {video.transcript}
                    </Modal>
                }
            </section>
        )
    }
}

export default mediaQueryHOC(ContentVideo);
// This export is for unit testing (do not remove) :
export const ContentVideoTest = ContentVideo;

ContentVideo.propTypes = {
    isMedium: PropTypes.bool,
    isXSmall: PropTypes.bool,
    isXLarge: PropTypes.bool,
};
